var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _vm.id != "new"
            ? _c(
                "b-row",
                { staticClass: "tab-button-row" },
                [
                  _c(
                    "b-col",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn",
                          attrs: { to: { name: "label-detail" }, tag: "button" }
                        },
                        [_vm._v("Overzicht")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "btn",
                          attrs: {
                            to: { name: "label-detail-edit" },
                            tag: "button"
                          }
                        },
                        [_vm._v("Bewerken")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.label == null && this.id != "new"
            ? _c(
                "b-row",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-center" },
                    [_c("b-spinner", { attrs: { label: "Spinning" } })],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm.label != null || this.id == "new"
        ? _c("router-view", {
            attrs: { id: _vm.id, label: _vm.label },
            on: { refresh: _vm.load }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }