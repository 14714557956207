<template>
  <div>
    <b-container fluid>
      <b-row class="tab-button-row" v-if="id != 'new'">
        <b-col>
          <router-link :to="{ name: 'label-detail' }" tag="button" class="btn">Overzicht</router-link>
        </b-col>
        <b-col>
          <router-link :to="{ name: 'label-detail-edit' }" tag="button" class="btn">Bewerken</router-link>
        </b-col>
      </b-row>

      <b-row v-if="label == null && this.id != 'new'" class="mt-5">
        <b-col class="text-center">
          <b-spinner label="Spinning"></b-spinner>
        </b-col>
      </b-row>
    </b-container>

    <router-view v-if="label != null || this.id == 'new'" :id="id" :label="label" v-on:refresh="load" />
  </div>
</template>

<script>
  export default {
    name: 'LabelDetailContainer',
    data () {
      return {
        label: null,
      }
    },
    props: {
      id: String,
    },
    watch: {
      id: function () {
        this.load()
      }
    },
    methods: {
      load () {
        this.label = null

        if (this.id == 'new') {
          return
        }

        this.$http.get('labels/'+this.id)
          .then(response => {
            this.label = response.data
          })
          .catch((error) => {
            console.log(error)
          });
        }
    },
    mounted () {
      this.load()
    }
  }
</script>